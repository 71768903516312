import React from "react";

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
	        portalId: "19651454",
	        formId: "3d0f380c-75d2-47fc-b450-f01357612e4e",
          target: "#hubspotForm"
        })
      }
    })
  }

  render() {
    return (
      <div id="hubspotForm" style={{minHeight: '450px'}}></div>
    )
  }
}

export default HubspotForm