import React from 'react'
import { Link } from "gatsby"

import Seo from '~/components/seo'
import Arrow from "~/images/common_icon_arrow_r.svg";
import HubspotForm from '~/components/modules/contact-order-hubspot'

const ContactOrder = () => (
  <>
    <Seo title="ご注文前のお問い合わせ" pageUrl='contact-order' />
    <div className="pageComponent">
      <nav className="contentComponent">
        <ul className="breadcrumbComponent mincho">
          <li><Link to="/" itemProp="url">TOP</Link> <span><Arrow /></span></li>
          <li>Contact</li>
        </ul>
      </nav>
      <article
        className="contact"
        itemScope
        itemType="http://schema.org/Article">
        <header className="titleComponent">
          <h1 className="contentComponent heading with-english"><span>ご注文前のお問い合わせ</span>Contact</h1>
          <hr className="borderLine"/>
        </header>
        <div className="contentComponent">
          <HubspotForm />
        </div>
      </article>
    </div>
  </>
)

export default ContactOrder
